import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { useTranslation } from 'react-i18next';

import AppstoreCta from '@/components/AppstoreCta';
import GooglePlayCta from '@/components/GooglePlayCta';

const TryIt = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="grey-section mb-[-45px] xl:mb-32">
      <div className="xl:w-inner xl:mx-auto xl:flex justify-between">
        <div className="px-6 xl:px-0 xl:w-[50%]">
          <h2>
            {t(`tryItTitle1-1`)}
            <span className="text-red">{t(`tryItTitle1-2`)}</span> !
          </h2>
          <p className="font-system font-[200] text-[17px]">
            {t(`tryItText1`)}
            <a
              href="https://charrette.club"
              target="_blank"
              rel="noreferrer"
              className="font-[700] underline"
            >
              charrette.club.
            </a>
            {t(`tryItText2`)}
          </p>
          <div className="flex flex-col xl:flex-row xl:gap-2 mt-8 mx-auto xl:mx-0 items-center">
            <AppstoreCta />
            <GooglePlayCta />
          </div>
        </div>
        <div className="w-[50%] mr-[-90px] hidden xl:block">
          <StaticImage
            src="../../../assets/app-screenshots/3-screens.webp"
            placeholder="blurred"
            alt="La fraise app"
          />
        </div>
      </div>
    </div>
  );
};

export default TryIt;
