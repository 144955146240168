import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { useTranslation } from 'react-i18next';

import Alert from '@/assets/icn/rounded/alert.svg';
import Heart from '@/assets/icn/rounded/heart.svg';
import Information from '@/assets/icn/rounded/information.svg';
import Search from '@/assets/icn/rounded/search.svg';
import UpToDate from '@/assets/icn/rounded/up-to-date.svg';
import Verified from '@/assets/icn/rounded/verified.svg';

const FeatureItem = ({
  title,
  content,
  Icon,
  alignRight = false,
}): JSX.Element => {
  const iconElement = (
    <div className="hidden xl:block">
      <Icon width="18" />
    </div>
  );

  return (
    <div className="relative mt-16 flex justify-center gap-4">
      {!alignRight && iconElement}
      <div
        className={`text-center xl:text-left ${
          alignRight ? `xl:text-right` : ``
        } w-[300px] xl:w-auto`}
      >
        <h4>{title}</h4>
        <p className="font-[300] text-[14px]">{content}</p>
      </div>
      {alignRight && iconElement}
    </div>
  );
};

const Features = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="xl:w-wide xl:mx-auto mt-20 lg:mt-40">
      <h2 className="text-center">{t(`applicationTitle`)}</h2>
      <p className="font-bold text-center md:w-[700px] px-6 md:px-0 mx-auto text-[18px]">
        {t(`applicationDescription`)}
      </p>
      <div className="mt-20 xl:flex xl:justify-between gap-20 xl:gap-0">
        {/* Left part */}
        <div className="xl:w-[380px] mt-12 ">
          <FeatureItem
            title={t(`applicationFeatureItemTitle1`)}
            content={t(`applicationFeatureItemDescription1`)}
            Icon={Search}
            alignRight
          />
          <FeatureItem
            title={t(`applicationFeatureItemTitle2`)}
            content={t(`applicationFeatureItemDescription2`)}
            Icon={Information}
            alignRight
          />
          <FeatureItem
            title={t(`applicationFeatureItemTitle3`)}
            content={t(`applicationFeatureItemDescription3`)}
            Icon={UpToDate}
            alignRight
          />
        </div>

        <StaticImage
          src="../../../assets/app-screenshots/1-screen.webp"
          alt="La fraise app"
          placeholder="blurred"
          className="max-w-[350px] hidden xl:block"
        />

        {/* Right part */}
        <div className="xl:w-[380px] my-12">
          <FeatureItem
            title={t(`applicationFeatureItemTitle4`)}
            content={t(`applicationFeatureItemDescription4`)}
            Icon={Verified}
          />
          <FeatureItem
            title={t(`applicationFeatureItemTitle5`)}
            content={t(`applicationFeatureItemDescription5`)}
            Icon={Alert}
          />
          <FeatureItem
            title={t(`applicationFeatureItemTitle6`)}
            content={t(`applicationFeatureItemDescription6`)}
            Icon={Heart}
          />
        </div>
      </div>
    </div>
  );
};

export default Features;
